import {jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {MainLayout} from '~/layouts/main';
import VisuallyHidden from '~/components/VisuallyHidden';
import Abbr from '~/components/Formatters/Abbr';
export const meta = {
  title: 'Введение',
  description: 'Эта книга рассказывает о 5 принципах объектно-ориентированного программирования SOLID и как они помогают проектировать надёжные программные системы'
};
const MDXLayout = ({children}) => _jsx(MainLayout, {
  meta: meta,
  children: children
});
function _createMdxContent(props) {
  const _components = {
    a: "a",
    h1: "h1",
    h2: "h2",
    li: "li",
    p: "p",
    section: "section",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_components.section, {
    children: [_jsx(_components.h1, {
      children: "О чём это"
    }), _jsx(_components.p, {
      children: "Программировать — сложно."
    }), _jsx(_components.p, {
      children: "Хороший код адекватно отражает систему, которую описывает, он устойчив к изменениям в этой системе. Плохой код запутанный, хрупкий и непонятный — он замедляет разработку."
    }), _jsx(_components.p, {
      children: "Код становится плохим, когда он перестаёт соответствовать реальности — бизнес-требованиям, правилам поведения частей системы, их отношениям друг с другом."
    }), _jsxs(_components.p, {
      children: ["Бизнес-правила — это территория. Код — ", _jsx(_components.a, {
        href: "https://wiki.lesswrong.com/wiki/The_map_is_not_the_territory",
        children: "карта"
      }), " этой территории. Чем точнее карта, тем проще\nсправляться с изменениями в требованиях и даже предвидеть их."]
    }), _jsxs(_components.p, {
      children: ["В этой книге мы хотим рассказать и показать на примерах, как ", _jsx(_components.a, {
        href: "https://ru.wikipedia.org/wiki/%D0%9E%D0%B1%D1%8A%D0%B5%D0%BA%D1%82%D0%BD%D0%BE-%D0%BE%D1%80%D0%B8%D0%B5%D0%BD%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%BD%D0%BE%D0%B5_%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5",
        children: "принципы объектно-ориентированного программирования"
      }), "\nмогут помочь спроектировать устойчивую систему."]
    }), _jsxs(_components.section, {
      children: [_jsx(_components.h2, {
        children: "Почему ООП?"
      }), _jsx(_components.p, {
        children: "Объектно-ориентированное программирование, ООП, вызывает споры."
      }), _jsx(_components.p, {
        children: "Война парадигм может создать впечатление, что подход ООП устарел и плох. Но ООП — это всего лишь инструмент. У каждого инструмента есть удобства, недостатки и область применения. Инструмент должен оставаться способом решения задачи и не должен становиться самоцелью."
      }), _jsx(_components.p, {
        children: "ООП, как инструмент, может помочь спроектировать систему на языке, более близком к языку бизнес-правил. Это снижает вероятность ошибки при переводе с «языка бизнеса» на\n«язык разработки» и наоборот."
      })]
    }), _jsxs(_components.section, {
      children: [_jsx(_components.h2, {
        children: "О каких принципах пойдёт речь?"
      }), _jsx(_components.p, {
        children: "Мы рассмотрим 5 принципов SOLID, а именно:"
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: [_jsx(_components.a, {
            href: "/srp",
            children: "принцип единственной ответственности"
          }), " (single responsibility principle);"]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.a, {
            href: "/ocp",
            children: "открытости и закрытости"
          }), " (open/closed principle);"]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.a, {
            href: "/lsp",
            children: "подстановки Барбары Лисков"
          }), " (Liskov substitution principle);"]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.a, {
            href: "/isp",
            children: "разделения интерфейса"
          }), " (interface segregation principle);"]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.a, {
            href: "/dip",
            children: "инверсии зависимостей"
          }), " (dependency inversion principle)."]
        }), "\n"]
      }), _jsx(_components.p, {
        children: "Каждый из них — это лишь рекомендация, все они имеют область и границы применения. Но чтобы увидеть эти границы, необходимо понять, в чём польза и издержки\nкаждого."
      }), _jsxs(_components.p, {
        children: ["Многие принципы вам покажутся чрезмерно абстрактными, неконкретными или вовсе надуманными. Отнеситесь к таким принципам, как к ", _jsx(_components.a, {
          href: "https://www.python.org/dev/peps/pep-0020/#id2",
          children: "дзену Python"
        }), " — держите в голове, но проверяйте, насколько они полезны в конкретной ситуации."]
      }), _jsx(_components.p, {
        children: "Мы в этой книге предлагаем ещё одну из бесконечных интерпретаций этих принципов, попутно расписывая пользу и ограничения каждого. Зная пользу и ограничения, можно оценить, насколько конкретный принцип помогает решить задачу, стоящую перед вами."
      })]
    }), _jsxs(_components.section, {
      children: [_jsx(_components.h2, {
        children: "Какой план?"
      }), _jsx(_components.p, {
        children: "Каждый раздел будет описывать один из принципов и показывать, как им пользоваться в повседневной работе."
      }), _jsxs(_components.p, {
        children: ["Мы будем рассматривать примеры на TypeScript, так как в нём есть понятия, которые нам пригодятся по ходу повествования. Если вы чувствуете себя неуверенно с TypeScript, попробуйте прочесть книгу ", _jsx(_components.a, {
          href: "https://basarat.gitbooks.io/typescript/",
          children: "TypeScript Deep Dive"
        }), " — она содержит все концепции, которые мы будем использовать."]
      }), _jsx(_components.p, {
        children: "В конце разделов вы найдёте проверочные вопросы. Каждый правильно отвеченный вопрос увеличивает количество очков на вашем счету. Максимально-возможный счёт — 100 очков. Отвечайте аккуратно — вопросы с подвохом. Обратите внимание, вариантов ответа может быть больше одного."
      })]
    }), _jsxs(_components.section, {
      children: [_jsx(_components.h2, {
        children: "Материалы к разделу"
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: _jsx(_components.a, {
            href: "https://en.wikipedia.org/wiki/Object-oriented_programming",
            children: "Объектно-ориентированное программирование"
          })
        }), "\n", _jsx(_components.li, {
          children: _jsx(_components.a, {
            href: "https://www.youtube.com/watch?v=TMuno5RZNeE",
            children: "Bob Martin SOLID Principles of Object Oriented and Agile Design, Youtube"
          })
        }), "\n", _jsx(_components.li, {
          children: _jsx(_components.a, {
            href: "https://wiki.lesswrong.com/wiki/The_map_is_not_the_territory",
            children: "Карта ≠ территория"
          })
        }), "\n", _jsx(_components.li, {
          children: _jsx(_components.a, {
            href: "https://basarat.gitbooks.io/typescript/",
            children: "TypeScript Deep Dive"
          })
        }), "\n"]
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  return _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  });
}
